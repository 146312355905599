import tw, { styled } from "twin.macro"

export const NameWrapper = tw.div`
  flex flex-col md:flex-row
`

export const CheckboxWrapper = tw.div`
  mt-2 md:mt-1-6 mb-0-8
`

export const TermsWrapper = styled.div`
  ${tw`mb-3-6 md:mb-4 text-center md:text-left`}
  p {
    ${tw`text-grey text-12 leading-1.5`}
  }
  a {
    ${tw`underline`}
  }
`
