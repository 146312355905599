import React from "react"
import { Link } from "gatsby"

import { withRegisterForm } from "./withRegisterForm"
import { NameWrapper, CheckboxWrapper, TermsWrapper } from "./RegisterFormStyles"
import { Submit, Errors, Error, Additional, AdditionalSpan } from "../../Layout/LayoutStyles"
import { StyledInput } from "../../../Styled/Form"
import { CheckboxInput } from "../../../Checkbox/Checkbox"
import { RichText } from "../../../RichText/RichText"

export const RegisterForm = withRegisterForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    loginUrl,
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalEmailPlaceholder,
    additionalPasswordPlaceholder,
    additionalNewsletterText,
    additionalSubmitButton,
    additionalTermsAndConditions,
    additionalLoginText,
    additionalLoginHighlightWords,
  }): JSX.Element => (
    <form onSubmit={handleSubmit}>
      <NameWrapper>
        <StyledInput
          placeholder={additionalFirstNamePlaceholder}
          name="firstName"
          type="text"
          value={data?.firstName}
          onChange={handleChange}
          first
          required
          spacing={"true"}
        />
        <StyledInput
          placeholder={additionalLastNamePlaceholder}
          name="lastName"
          type="text"
          value={data?.lastName}
          onChange={handleChange}
          last
          required
          spacing={"true"}
        />
      </NameWrapper>
      <StyledInput
        placeholder={additionalEmailPlaceholder}
        name="email"
        type="email"
        value={data?.email}
        onChange={handleChange}
        required
        spacing={"true"}
      />
      <StyledInput
        placeholder={additionalPasswordPlaceholder}
        name="password"
        type="password"
        value={data?.password}
        onChange={handleChange}
        required
        spacing={"true"}
      />
      <CheckboxWrapper>
        <CheckboxInput title={additionalNewsletterText} name="acceptsMarketing" checked={!!data?.acceptsMarketing} onChange={handleChange}>
          {additionalNewsletterText}
        </CheckboxInput>
      </CheckboxWrapper>
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Submit wide={"true"} type={`submit`} disabled={loading}>
        {additionalSubmitButton}
      </Submit>
      <TermsWrapper>
        <RichText>{additionalTermsAndConditions}</RichText>
      </TermsWrapper>
      <Additional cols={"1"}>
        <Link to={loginUrl} title={additionalLoginHighlightWords}>
          <AdditionalSpan>{additionalLoginText[0]}</AdditionalSpan>
          {additionalLoginHighlightWords ? <AdditionalSpan highlight={"true"}>{additionalLoginHighlightWords}</AdditionalSpan> : null}
          {additionalLoginText.length > 1 ? <AdditionalSpan>{additionalLoginText?.[1]}</AdditionalSpan> : null}
        </Link>
      </Additional>
    </form>
  )
)
